export const menuItems = [
  {
    id: 1,
    label: 'menuitems.menu.text',
    isTitle: true
  },
  {
    id: 2,
    label: 'menuitems.dashboards.text',
    icon: 'bx-home-circle',
    link: '/events'
  },
  {
    id: 3,
    label: 'menuitems.apps.text',
    isTitle: true
  },
  // {
  //   id: 4,
  //   label: 'menuitems.company.text',
  //   icon: 'mdi mdi-bank',
  //   link: '/company'
  // },
  {
    id: 5,
    label: 'menuitems.myevent.text',
    icon: 'bx-laptop',
    link: '/events'
  },
  {
    id: 6,
    label: 'menuitems.users.text',
    icon: 'bxs-user-detail',
    link: '/users'
  },
  {
    id: 7,
    label: 'menuitems.invoices.text',
    icon: 'dripicons-document',
    link: '/invoices'
  }
]
